import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faInstagram,
    faWhatsapp
} from "@fortawesome/free-brands-svg-icons";

export const Contact = (datos) => {
    const [nombre, setNombre] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [telefono, setTelefono] = React.useState('');
    const [consulta, setConsulta] = React.useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Nombre", nombre);
        console.log("Email", email);
        console.log("Telefono", telefono);
        console.log("Consulta", consulta);

        // Mandar mail, whatsapp con esto y guardarlo en la base
    }

    const sanitizarCelu = celu => {
        let str = celu.split(' ').join('');
        return str.split('-').join('');
    }

    return (
        <div id="#contacto" className="row Section">
            <div className='col-md-12 Contact'>
                <hr/>
                <h4>Contacto</h4>

                <br/>
                <p>Podés enviarme un mensaje directo en el siguiente formulario:</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label form="nombre">Nombre</label>
                        <input
                            type="text"
                            className="form-control"
                            id="nombre"
                            aria-describedby="emailHelp"
                            placeholder="Tu nombre"
                            onChange={(e) => {setNombre(e.target.value)}}
                        />
                    </div>
                    <div className="form-group">
                        <label form="email">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Tu email"
                            onChange={(e) => {setEmail(e.target.value)}}
                        />
                    </div>
                    <div className="form-group">
                        <label form="telefono">Teléfono</label>
                        <input type="number"
                           className="form-control"
                           id="telefono"
                           placeholder="Tu teléfono"
                           onChange={(e) => {setTelefono(e.target.value)}}
                        />
                    </div>
                    <div className="form-group">
                        <label form="consulta">Consulta</label>
                        <textarea
                            className="form-control"
                            id="consulta"
                            rows="6"
                            onChange={(e) => {setConsulta(e.target.value)}}
                        ></textarea>
                    </div>
                    <br></br>
                    <button type="submit" className="btn btn-primary">Enviar!</button>
                </form>
                <br/>
                <div className="row Social-contact">
                    <p>O bien contactarme en:</p>
                    <div className="col-md-6 Instagram-link">
                        <a className="Social-link" href={'https://www.instagram.com/' + datos.data.instagram} target="_blank">
                            <FontAwesomeIcon icon={faInstagram} size="x4"/> {datos.data.instagram}
                        </a>
                    </div>
                    <div className="col-md-6 Whatsapp-link">
                        <a className="Social-link" href={'https://wa.me/' + sanitizarCelu(datos.data.whatsapp)} target="_blank">
                            <FontAwesomeIcon icon={faWhatsapp} size="x4"/> {datos.data.whatsapp}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}