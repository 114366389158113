import React from 'react';
import db from "../../firebase";
import { Loading } from "../Loading";
import bcrypt from 'bcryptjs';
import { hash } from "../Helpers";
import { Navigate } from "react-router-dom";

export const Login = () => {
    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [clave, setClave] = React.useState('');

    const [admin, setAdmin] = React.useState([]);
    const [usuarioValido, setUsuarioValido] = React.useState(true);
    const [logueado, setLogueado] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        setLogueado(localStorage.getItem("usuario") !== null);

        db.collection('usuario').onSnapshot(snapshot => {
            setAdmin(snapshot.docs.map(doc => {
                return doc.data();
            }))

            setLoading(false);
        })
    }, [])

    const loginHandler = (e) => {
        e.preventDefault();

        bcrypt.compare(clave, hash).then((res) => {
            if (res) {
                if (email === admin[0].email) {
                    localStorage.setItem("usuario", email);
                    setLogueado(true);
                } else {
                    setUsuarioValido(false);
                }
            } else {
                setUsuarioValido(false);
            }
        });
    }

    return (
        <div>
            { loading ? (
                <Loading />
            ) : (
                <div>
                    { logueado ? (
                        <Navigate to="/admin" />
                    ) : (
                        <div className="Login">
                            <div className="card Login-card">
                                <h2>Iniciar Sesión</h2>

                                { !usuarioValido && <p className="Error">Usuario o clave inválidos</p>}
                                <form onSubmit={loginHandler}>
                                    <div className="form-group">
                                        <label form="email">Email</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="email"
                                            aria-describedby="email"
                                            onChange={(e) => {setEmail(e.target.value)}}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label form="clave">Clave</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="clave"
                                            onChange={(e) => {setClave(e.target.value)}}
                                        />
                                    </div>
                                    <br></br>
                                    <button type="submit" className="btn btn-primary btn-iniciar-sesion">¡Iniciar Sesión!</button>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}