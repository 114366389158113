import React from 'react';
import { Navigate } from "react-router-dom";
import db from "../../firebase";
import {Loading} from "../Loading";

export const Admin = () => {
    const [loading, setLoading] = React.useState(true);
    const [usuario, setUsuario] = React.useState(null);

    const [informacionId, setInformacionId] = React.useState('');
    const [informacion, setInformacion] = React.useState({});
    const [nombre, setNombre] = React.useState();
    const [titulo, setTitulo] = React.useState();
    const [matricula, setMatricula] = React.useState();
    const [frase, setFrase] = React.useState();
    const [autor, setAutor] = React.useState();
    const [sobreMi, setSobreMi] = React.useState();
    const [instagram, setInstagram] = React.useState();
    const [whatsapp, setWhatsapp] = React.useState();

    const [guardado, setGuardado] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        setUsuario(localStorage.getItem("usuario"));

        db.collection('informacion').onSnapshot(snapshot => {
            setInformacion(snapshot.docs.map(doc => {
                setNombre(doc.data().datosPersonales.nombre)
                setTitulo(doc.data().datosPersonales.titulo)
                setMatricula(doc.data().datosPersonales.matricula)
                setFrase(doc.data().frase.frase)
                setAutor(doc.data().frase.autor)
                setSobreMi(doc.data().informacion)
                setInstagram(doc.data().contacto.instagram)
                setWhatsapp(doc.data().contacto.whatsapp)
                setInformacionId(doc.id);

                setLoading(false);

                return doc.data();
            }))
        })
    }, [])

    const cerrarSesionHandler = (e) => {
        e.preventDefault();
        localStorage.removeItem("usuario");
        setUsuario(null)
    }

    const guardarHandler = (e) => {
        e.preventDefault();

        let nuevaInformacion = {
            contacto: {
                instagram: instagram,
                whatsapp: whatsapp
            },
            datosPersonales: {
                matricula: matricula,
                nombre: nombre,
                titulo: titulo
            },
            frase: {
                autor: autor,
                frase: frase
            },
            informacion: sobreMi
        }

        db.collection("informacion").doc(informacionId).update(nuevaInformacion);
        setGuardado(true);
    }

    return (
        <div>
            { loading ? (
                <Loading />
            ) : (
                <div>
                    { !usuario && !loading ? (
                        <Navigate to={"/login"}></Navigate>
                    ) : (
                        <div className="Admin-panel">
                            <nav className="navbar navbar-expand-lg">
                                <a className="navbar-brand">Administración</a>
                                <ul className="navbar-nav mr-auto">
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={cerrarSesionHandler}>Cerrar Sesión</a>
                                    </li>
                                </ul>
                            </nav>

                            <div className="container">
                                <div className="card Admin-card">
                                    <form onSubmit={guardarHandler}>
                                        <h2>Información Personal</h2>

                                        <div className="formulario">
                                            <div className="form-group">
                                                <label form="nombre">Nombre</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="nombre"
                                                    aria-describedby="email"
                                                    value={nombre}
                                                    onChange={(e) => {setNombre(e.target.value)}}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label form="titulo">Titulo</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="titulo"
                                                    aria-describedby="email"
                                                    value={titulo}
                                                    onChange={(e) => {setTitulo(e.target.value)}}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label form="matricula">Matricula</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="matricula"
                                                    aria-describedby="email"
                                                    value={matricula}
                                                    onChange={(e) => {setMatricula(e.target.value)}}
                                                />
                                            </div>
                                        </div>

                                        <br></br>
                                        <h2>Frase</h2>

                                        <div className="formulario">
                                            <div className="form-group">
                                                <label form="frase">Frase</label>
                                                <textarea
                                                    className="form-control"
                                                    id="frase"
                                                    rows="5"
                                                    value={frase}
                                                    onChange={(e) => {setFrase(e.target.value)}}
                                                ></textarea>
                                            </div>
                                            <div className="form-group">
                                                <label form="titulo">De quien?</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="quien"
                                                    aria-describedby="email"
                                                    value={autor}
                                                    onChange={(e) => {setAutor(e.target.value)}}
                                                />
                                            </div>
                                        </div>

                                        <br></br>
                                        <h2>Sobre Mi</h2>

                                        <div className="formulario">
                                            <div className="form-group">
                                                <label form="sobre-mi">Sobre mi</label>
                                                <textarea
                                                    className="form-control"
                                                    id="sobre-mi"
                                                    rows="6"
                                                    value={sobreMi}
                                                    onChange={(e) => {setSobreMi(e.target.value)}}
                                                ></textarea>
                                            </div>
                                        </div>

                                        <br></br>
                                        <h2>Contacto</h2>

                                        <div className="formulario">
                                            <div className="form-group">
                                                <label form="instagram">Instagram</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="instagram"
                                                    aria-describedby="email"
                                                    value={instagram}
                                                    onChange={(e) => {setInstagram(e.target.value)}}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label form="whatsapp">Whatsapp</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="whatsapp"
                                                    aria-describedby="email"
                                                    value={whatsapp}
                                                    onChange={(e) => {setWhatsapp(e.target.value)}}
                                                />
                                            </div>
                                        </div>

                                        <br></br>
                                        <button type="submit" className="btn btn-primary btn-iniciar-sesion">¡Guardar!</button>
                                        {guardado && (
                                            <p style={{color: "green"}}>¡Guardado!</p>)
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}