import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

export const Header = (datos) =>  {
    return (
        <div className='row Header'>
            <div className='col-md-6 Datos-personales-col'>
                <div className="row Datos-personales-container">
                    <div className="col-md-12 Datos-personales">
                        <p className='Nombre'>{datos.data.datosPersonales.nombre}</p>
                        <p className='Titulo'>{datos.data.datosPersonales.titulo}</p>
                        <p className='Matricula'>{datos.data.datosPersonales.matricula}</p>
                    </div>
                </div>
            </div>
            <div className='col-md-6 Frase-col'>
                <div className='row Frase-container'>
                    <div className='row Frase-container'>
                        <div className='col-md-12 Frase'>
                            {datos.data.frase.frase.split("\n").map(function(linea, i) {
                                if (i === 0) {
                                    return <p>"{linea}</p>;
                                } else if (i === datos.data.frase.frase.split("\n").length) {
                                    return <p>{linea}</p>;
                                } else {
                                    return <p>{linea}"</p>;
                                }
                            })}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12 Frase-autor'>
                            <p>{datos.data.frase.autor}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
