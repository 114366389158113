import React from 'react';


export const About = (datos) => {
    return (
        <div id="#terapias" className="row Section">
            <div className='col-12 About'>
                <hr/>
                <h4>Sobre mí...</h4>

                {datos.data.split("\n").map(function(linea, i) {
                    return <p>{linea}</p>;
                })}
            </div>
        </div>
    )
}