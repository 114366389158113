import { initializeApp } from "firebase/app";

require('firebase/firestore');

const app = initializeApp({
    apiKey: "AIzaSyDlvqCNJAZBxAc_AtWh5JNsEYfLGENZsNc",
    authDomain: "pagina-cami.firebaseapp.com",
    projectId: "pagina-cami",
    storageBucket: "pagina-cami.appspot.com",
    messagingSenderId: "427039494394",
    appId: "1:427039494394:web:f856954deebac3d810b943",
    measurementId: "G-PNH9JDLG91"
});

const db = app.firestore()

export default db;