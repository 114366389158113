import React from 'react';
import ReactLoading from 'react-loading';

export const Loading = () => {
    return (
        <div className="Cargando">
            <ReactLoading
                className="Cargando-logo"
                type={"bubbles"}
                color={"#dbc2a9"}
                height={100}
                width={100}
            />
        </div>
    )
}