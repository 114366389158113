import React from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import { Home } from "./components/home/Home";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import {Admin} from "./components/admin/Admin";
import {Login} from "./components/home/Login";

function App() {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Home />}/>
                <Route exact path="/login" element={<Login />}/>
                <Route path="/admin" element={<Admin />}/>
            </Routes>
        </Router>
    )
}

export default App;
