import React from 'react';
import db from "../../firebase";
import { Loading } from "../Loading";
import { Header } from "./Header";
import { About } from "./About";
import { Contact } from "./Contact";

export const Home = () => {
    const [loading, setLoading] = React.useState(true);
    const [datos, setDatos] = React.useState([]);
    const [headerData, setHeaderData] = React.useState({});
    const [aboutData, setAboutData] = React.useState({});
    const [contactData, setContactData] = React.useState({});

    React.useEffect(() => {
        setLoading(true);

        db.collection('informacion').onSnapshot(snapshot => {
            setDatos(snapshot.docs.map(doc => {
                parseHeaderData(doc.data())
                parseAboutData(doc.data())
                parseContactData(doc.data())
                setLoading(false);
            }))
        })
    }, [])

    const parseHeaderData = (datos) => {
        setHeaderData({
            frase: datos.frase,
            datosPersonales: datos.datosPersonales
        })
    }

    const parseAboutData = (datos) => {
        setAboutData(datos.informacion);
    }

    const parseContactData = (datos) => {
        setContactData(datos.contacto);
    }

    if (loading) {
        return <Loading />
    } else {
        return (
            <div className="App">
                <Header data={headerData}/>
                <About data={aboutData}/>
                <Contact data={contactData}/>
            </div>
        );
    }
}